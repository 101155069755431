import React from "react";
import styled from "@emotion/styled";
import mediaqueries from "@styles/media";
import { css } from '@emotion/core';

import Section from "@components/Section";
import SEO from "@components/SEO";
import Image from '@components/Image';
import Layout from "@components/Layout";
import { graphql, useStaticQuery, Link } from "gatsby";

import { Template } from "@types";

const siteQuery = graphql`
{
  file(relativePath: {eq: "Trong-White.jpg"}) {
    id
    childImageSharp {
      fluid(maxWidth: 540, quality: 100, base64Width: 1) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;

const AboutPage: Template = ({ location, pageContext }) => {
  
  const avatarResult = useStaticQuery(siteQuery);
  
  return (
    <Layout isDark={true}>
      <SEO pathname={location.pathname} title={"Trong, Product Designer"}/>
      <Section narrow >
        <HeadingContainer>
          <HeroHeading>Hi! <br/> I’m Trong, <br/> Product Designer.</HeroHeading>
        </HeadingContainer>
      </Section>
      <Section narrow >
        <ContentContainer>
          <MyImage><Image src={avatarResult.file.childImageSharp.fluid} /></MyImage>
          <MyText>
            <IntroText>
              Currently, I'm building Design System and improving Web Experience at 
              <Anchor target="_blank" href="http://carousell.com">
                Carousell.
              </Anchor>
              <InfoText>
                In the free time, I share my love for design in
                <InternalLink to={`/design-tips`} title={`Visual storytelling`}>
                  the visual language
                </InternalLink>
              
                , and making stuff for my side project
                <Anchor target="_blank" rel="noopener nofollow" data-a11y="false" href="https://awesomefigmatips.com">
                  Awesome Figma Tips
                </Anchor>
                &nbsp; &
                <Anchor target="_blank" rel="noopener nofollow" data-a11y="false" href="https://www.pafolios.com/">
                  Pafolios
                </Anchor>
                &nbsp; &
                <Anchor target="_blank" rel="noopener nofollow" data-a11y="false" href="https://www.visualizem.cc/">
                  VisualizemCC.
                </Anchor>
              </InfoText>
            </IntroText>
            <InfoHeading>
              Born and raised in a small village in Viet Nam, been hard at work since 2015.
            </InfoHeading>
            <InfoText>
              Trong is a Singapore-Based Product Designer, currently building Carousell classified marketplace that inspires people to start to sell and buy.
            </InfoText>
            <InfoText>
              Previously, he built the e-commerce Leflair Viet Nam, an online retailer that focuses on beauty, fashion and home living products.
            </InfoText>
            <InfoText>
              He spent 5 years in a tech university to learn IT, but he soon realized design is his passion. He hated coding, but his gut told him to finish the engineering diploma while learning to design in his free time. And when he met the UX term, basic knowledge in IT became an important foundation in his career.
            </InfoText>
            <InfoText>
              One of the greatest things about being a designer with an engineering background is that not only he can take care of the graphical aspects of a project, but can also fully understand, participate and even take care of the technical aspects of those projects.
            </InfoText>
          </MyText>
        </ContentContainer>
      </Section>
    </Layout>
  );
};

export default AboutPage;

const HeadingContainer = styled.div`
  padding: 280px 0 96px;
  font-family: ${p => p.theme.fonts.title};

  ${mediaqueries.desktop`
    width: 80%;
  `}

  ${mediaqueries.tablet`
    width: 100%;
  `}
`;

const HeroHeading = styled.h2`
  // font-style: normal;
  // font-weight: ${p => p.theme.fontsWeight.bold};
  // // max-width: 800px;
  
  font-family: ${p => p.theme.fonts.titleEm};
  font-weight: ${p => p.theme.fontsWeight.regular};
  font-style: italic;
  text-transform: uppercase;
  font-size: 160px;
  line-height: 1;
  letter-spacing: -4.5px;
  color: ${p => p.theme.colors.textPrimaryDark};

  a {
    color: ${p => p.theme.colors.accent};
  }

  ${mediaqueries.desktop`
    font-size: 64px
  `}

  ${mediaqueries.phablet`
    font-size: 60px;
  `}
`;

const ContentContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
  z-index: 1;

  ${mediaqueries.tablet`
    grid-template-columns: 1fr;
  `}
`;

const InfoHeading = styled.h1`
  font-weight: ${p => p.theme.fontsWeight.bold};
  font-family: ${p => p.theme.fonts.title};
  font-size: 32px;
  line-height: 1.35;
  max-width: 100%;
  margin-bottom: 32px;
  color: ${p => p.theme.colors.whiteSecondary};

  ${mediaqueries.phablet`
    font-size: 32px;
  `}
`;

const InfoText = styled.p`
  font-size: 22px;
  margin-top: 24px;
  line-height: 1.7;
  color: ${p => p.theme.colors.textSecondaryDark};
`;

const MyText = styled.div`
  position: relative;
`;

const IntroText = styled.div`
  font-size: 22px;
  margin-top: 24px;
  line-height: 1.7;
  color: ${p => p.theme.colors.textSecondaryDark};
  margin-bottom: 64px;
`;

const MyImage = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 56px;

  img {
    border-radius: 300px 300px 0 0;
    overflow: hidden;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
`;

const linkCSS = p => css`
    color: ${p.theme.colors.textSecondaryDark};
    border-bottom: 1px solid ${p.theme.colors.secondary};
    margin-left: 6px;
    
    &:hover,
    &:focus {
      color: ${p.theme.colors.accent};
      border-bottom-color: ${p.theme.colors.accent};
    }
`;

const Anchor = styled.a`
  ${linkCSS};
`;

const InternalLink = styled(Link)`
  ${linkCSS};
`;
